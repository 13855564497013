import * as XLSX from "xlsx";
import XLSXS from "xlsx-style";
import FileSaver from "file-saver";
import './BlobNew.js';

function datenum(v, date1904) {
    if (date1904) v += 1462;
    let epoch = Date.parse(v);
    return (epoch - new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000);
}

function sheet_from_array_of_arrays(data) {
    let ws = {};
    let range = {
        s: {
            c: 10000000,
            r: 10000000
        },
        e: {
            c: 0,
            r: 0
        }
    };
    for (let R = 0; R !== data.length; ++R) {
        for (let C = 0; C !== data[R].data.length; ++C) {
            if (range.s.r > R) range.s.r = R;
            if (range.s.c > C) range.s.c = C;
            if (range.e.r < R) range.e.r = R;
            if (range.e.c < C) range.e.c = C;
            let cell = {
                v: data[R].data[C],
                isErr: data[R].isErr
            };
            if (cell.v == null) continue;
            let cell_ref = XLSX.utils.encode_cell({
                c: C,
                r: R
            });
            if (typeof cell.v === 'number') cell.t = 'n';
            else if (typeof cell.v === 'boolean') cell.t = 'b';
            else if (cell.v instanceof Date) {
                cell.t = 'n';
                cell.z = XLSX.SSF._table[14];
                cell.v = datenum(cell.v);
            } else cell.t = 's';

            ws[cell_ref] = cell;
        }
    }
    if (range.s.c < 10000000) ws['!ref'] = XLSX.utils.encode_range(range);
    return ws;
}

function Workbook() {
    if (!(this instanceof Workbook)) return new Workbook();
    this.SheetNames = [];
    this.Sheets = {};
}

function s2ab(s) {
    let buf = new ArrayBuffer(s.length);
    let view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
}

function autoWidth(data) {
    /*设置worksheet每列的最大宽度*/
    const colWidth = data.map(row => row.data.map(val => {
        /*先判断是否为null/undefined*/
        if (val == null) {
            return {
                'wch': 10
            };
        }
        /*再判断是否为中文*/
        else if (val.toString().charCodeAt(0) > 255) {
            return {
                'wch': val.toString().length * 2 + 4 > 50 ? 50 : val.toString().length * 2 + 4
            };
        } else {
            return {
                'wch': val.toString().length + 4 > 50 ? 50 : val.toString().length + 4
            };
        }
    }))
    /*以第一行为初始值*/
    let result = colWidth[0];
    for (let i = 1; i < colWidth.length; i++) {
        for (let j = 0; j < colWidth[i].length; j++) {
            if (result[j]['wch'] < colWidth[i][j]['wch']) {
                result[j]['wch'] = colWidth[i][j]['wch'];
            }
        }
    }
    return result;
}

const uppercaseAlphabet = [];
for (let i = 65; i <= 90; i++) {
    const letter = String.fromCharCode(i);
    uppercaseAlphabet.push(letter);
}


function isKey(key) {//判断是不是表头
    let Y = false
    key.replace(/[\d\.\-]/g, '')
    let num = Number(key.replace(/[^\d\.\-]/g, '')) || 0
    if (num === 1) {
        Y = true
    }
    return Y
}

function export_json_to_excel(tHeader, jsonData, defaultTitle, headerStyle, wList) {
    /* original data */
    let data = jsonData;
    data.unshift({
        data: tHeader,
        isErr: false
    });
    let ws_name = "SheetJS";
    let wb = new Workbook();
    let ws = sheet_from_array_of_arrays(data);
    let mergesList = []
    data.forEach((item, index) => {
        if (item.isScattered) {
            if (wList.length) {
                wList.forEach((wItem) => {
                    if (data?.[index - 1]?.product === item.product) {
                        mergesList.push({
                            s: {r: index - 1, c: +wItem}, e: {r: index, c: +wItem}
                        })
                    }
                })
            }
        }
    })
    ws["!merges"] = mergesList
    ws['!cols'] = autoWidth(data); //设置单元格宽度自适应
    Object.keys(ws).forEach((key) => { //这里遍历单元格给单元格对象设置属性,s为控制样式的属性
        if (key.indexOf('!') < 0) {
            if (isKey(key) && headerStyle) {
                // 设置表头文字加粗字号突出
                ws[key].s = {
                    alignment: { //对齐方式
                        horizontal: 'center', //水平居中
                        vertical: 'center', //竖直居中
                    },
                    font: {
                        sz: 13, // 字号
                        bold: true, //加粗
                        name: '微软雅黑', // 字体
                        color: {rgb: 'FFFFFF'}, //字体颜色
                    },
                    fill: {
                        fgColor: { //单元格背景颜色
                            rgb: "6D71F8"
                        },
                        bgColor: {
                            rgb: "6D71F8"
                        }
                    }
                }
            } else if (ws[key].isErr) {
                ws[key].s = {
                    alignment: { //对齐方式
                        horizontal: 'center', //水平居中
                        vertical: 'center', //竖直居中
                        wrapText: true, //自动换行
                    },
                    font: {
                        sz: 12, // 字号
                        name: '微软雅黑', // 字体
                        color: {rgb: 'FF6669'}, //字体颜色
                    },
                }
            } else {
                //为所有的单元格设置样式
                ws[key].s = {
                    alignment: { //对齐方式
                        horizontal: 'center', //水平居中
                        vertical: 'center', //竖直居中
                        wrapText: true, //自动换行
                    },
                    font: {
                        sz: 12, // 字号
                        name: '微软雅黑' // 字体
                    },
                }
            }

        }
    })

    wb.SheetNames.push(ws_name);
    wb.Sheets[ws_name] = ws;
    let wbout = XLSXS.write(wb, {
        bookType: 'xlsx',
        bookSST: false,
        type: 'binary'
    });
    let title = defaultTitle || '表单';
    saveAs(new Blob([s2ab(wbout)], {
        type: "application/octet-stream"
    }), title + ".xlsx")
}

export default {
    // 导出Excel表格
    // name表示生成excel的文件名     IDName表示表格的id
    exportExcel(name, IDName) {
        const fix_l = document.querySelector('.el-table__fixed'); //fixed 或者 fixed='left'
        const fix_r = document.querySelector('.el-table__fixed-right'); //fixed='right'
        let wb;
        // 以下代码解决table中使用fixed属性导致导出的数据重复问题
        if (fix_l) { //判断是否有浮动或者是左浮动
            wb = XLSX.utils.table_to_book(document.querySelector(IDName).removeChild(fix_l));
            document.querySelector(IDName).appendChild(fix_l);
        } else if (fix_r) { //判断是否有右浮动
            wb = XLSX.utils.table_to_book(document.querySelector(IDName).removeChild(fix_r));
            document.querySelector(IDName).appendChild(fix_r);
        } else if (fix_l && fix_r) { //左右浮动都存在
            wb = XLSX.utils.table_to_book(document.querySelector(IDName).removeChild(fix_l).removeChild(fix_r));
            document.querySelector(IDName).appendChild(fix_l).appendChild(fix_r);
        } else { //没有浮动
            wb = XLSX.utils.table_to_book(document.querySelector(IDName))
        }
        // var wb = XLSX.utils.table_to_book(document.querySelector(IDName))
        const wbout = XLSX.write(wb, {
            bookType: 'xlsx',
            bookSST: true,
            type: 'array'
        })
        try {
            FileSaver.saveAs(new Blob([wbout], {
                type: 'application/octet-stream'
            }), `${name}.xlsx`);
        } catch (e) {
            if (typeof console !== 'undefined') console.log(e, wbout)
        }
        return wbout;
    },

    // 导出Excel表格自定义标头和内容
    // excelData表示el-table数据       tableName表示生成excel的文件名
    export2Excel(excelData, excelObj, tableName, headerStyle = false, wList = []) {
        require.ensure([], () => {
            // 导出的excel的表头字段可自定义
            // 对象属性，对应于tHeader，即prop的值，可自定义导出字段
            //excelObj 根据sort 字段排序
            excelObj.sort((a, b) => a.sort - b.sort)
            let tHeader = excelObj.map(item => item.title)
            let filterVal = excelObj.map(item => item.fieldKey)
            // 格式转换数据
            const data = excelData.map(v => {
                return {
                    data: filterVal.map(j => v[j]),
                    isErr: v.isErr || false,
                    isScattered: v.scattered || false,
                    product: (v.productId + '' + v.modelId) || ''
                }
            })
            export_json_to_excel(tHeader, data, tableName, headerStyle, wList) // 导出的表格名称，可自定义
        })
    },
}