<template>
  <img alt="位元灵感"
       :src="imgUrl"
       :style="{ width: `${props.size}px`, height: `${props.size}px` }"
       v-bind="attrs"
  />
</template>

<script setup>
import {defineProps, useAttrs} from 'vue'

let imgUrl = require('@img/index/export.png')
const attrs = useAttrs()
const props = defineProps({
  size: {
    type: Number,
    default: 14
  },
})


</script>

<style lang="scss" scoped>
.disable {
  //图片灰色
  filter: grayscale(100%);
}
</style>